import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Utils } from './utils';


@Injectable({
  providedIn: 'root'
})
export class BackendService {
  private backendURL: string;
  private currentDateString: string = "latest";

  constructor(
    private httpClient: HttpClient
  ) {
    this.backendURL = String(process.env.BACKEND_URL);
  }

  public setCurrentDate(date: Date) {
    this.currentDateString = Utils.dateToFolderName(date);
  }

  public getCurrentDate(): Date {
    return Utils.folderNameToDate(this.currentDateString);
  }

  public hasSpecificDate(): boolean {
    return this.currentDateString != "latest";
  }

  public getCurrentDateString(): string {
    return this.currentDateString;
  }

  public async getAvailableDates(): Promise<Array<Date>> {
    let response: Object = await this.httpClient.get(`${this.backendURL}/dates`).toPromise();
    let responseAsDates: Array<Date> = (response as Array<string>).map((dateString) => { return new Date(dateString); });
    return responseAsDates;
  }

  public async getRiskyDates(): Promise<Array<any>> {
    let response: Array<any> = (await this.httpClient.get(`${this.backendURL}/dangerDates`).toPromise()) as Array<any>;
    return response;
  }

  public getCurrentDateHTMLLabel(): string {
    let currentDate = this.getCurrentDate();
    let isValidDate: boolean = currentDate instanceof Date && !isNaN(currentDate.getTime());
    if (isValidDate) {
      return `${(currentDate.getUTCDate()).toString().padStart(2, "0")}/${(currentDate.getUTCMonth() + 1).toString().padStart(2, "0")}/${currentDate.getUTCFullYear()}`;
    } else {
      return "lang.latestDate";
    }
  }

  public async getSimulationData(): Promise<any> {
    let response: Object = await this.httpClient.get(`${this.backendURL}/${this.getCurrentDateString()}/simulations`).toPromise();
    return response;
  }
}
