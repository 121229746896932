<div class="container">
    <!-- FIXME: Translate this -->
    <h2>Sistema de Análisis y Simulación de Rebase para Puerto Exterior de A Coruña.</h2>
    <br>
    <p>
        El sistema permite predecir las posibilidades de que el oleaje rebase el dique de abrigo del Puerto Exterior de
        A Coruña situado en Punta Langosteira. Al tratarse de un puerto exterior, la zona carece de la protección
        natural que caracteriza a otras instalaciones, por lo que dispone de un dique de abrigo especialmente diseñado
        para ser rebasado en situaciones de fuerte temporal. El sistema ha sido desarrollado en colaboración por <b>EPhysLab CIM-UVigo</b> y <b>Universidade de Aveiro</b>.
        El sistema de predicción tiene en cuenta los diferentes forzamientos que pueden dar lugar a eventos de rebase
        tales como la altura, periodo y dirección de incidencia del oleaje, el viento o el estado de marea.
        El sistema está diseñado para trabajar con un horizonte de predicción de 24 horas a partir de las predicciones
        de oleaje externo y viento proporcionadas por <b>MeteoGalicia</b> e indica mediante un sencillo código de
        colores
        diferentes niveles de alerta.
        El modelo ha sido calibrado con eventos pasados de rebase proporcionados por <b>GEAMA</b> (Universidad de A
        Coruña),
        dentro del contexto del Proyecto <b>MarRISK</b>, <b>Interreg POCTEP</b>.
    </p>
    <a target="_blank" href="assets/img/captura_martillo.jpg">
        <img class="img-fluid" src="assets/img/captura_martillo.jpg" alt="" srcset="">
    </a>
    <small>Fotografía de un rebase en el Puerto Exterior de A Coruña</small>
    <br>
    <h2 class="mt-5">Aviso legal</h2>
    <p>
        Se advierte a los usuarios del sistema de la naturaleza provisional de la información antes de usarla para la
        toma de decisiones que afecten a la seguridad personal, pública o aplicadas de un negocio. La Universidad de
        Vigo no asumirá ninguna responsabilidad por cualquier daño o perjuicio ocurrido como consecuencia del uso de
        esta información.
    </p>
</div>