<div class="bg-light">
    <footer class="footer mt-5 py-3 bg-light">
        <div class="container d-flex justify-content-around align-items-center">
            <a class="logo" href="https://uvigo.gal/" target="_blank">
                <img class="img-fluid" src="/assets/img/logo_cim.png" alt="">
            </a>
            <a class="logo" href="https://www.meteogalicia.gal/" target="_blank">
                <img class="img-fluid" src="/assets/img/logo_meteogalicia.png" alt="">
            </a>
            <a class="logo" href="https://www.ua.pt/" target="_blank">
                <img class="img-fluid" src="/assets/img/logoUA.png" alt="">
            </a>
        </div>
        <div class="container d-flex justify-content-around align-items-center mt-5">
            <a class="logo mx-2" href="https://ephyslab.uvigo.es/" target="_blank">
                <img class="img-fluid" src="/assets/img/ephyslab_logo.png" alt="">
            </a>
            <a class="logo mx-2">
                <img class="img-fluid" src="/assets/img/Interreg_MarRISK_ES.png" alt="">
            </a>
        </div>
    </footer>
    <hr>
    <footer class="footer py-3 bg-light">
        <div class="container">
            <span class="text-muted">&copy; {{fullYear}} Overtopping. {{'lang.allRightsReserved' | translate}}</span>
        </div>
    </footer>
</div>