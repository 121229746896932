<div class="clearfix">
    <div class="float-right">
        <input class="form-control-sm date-form" disabled matInput [matDatepickerFilter]="availableDatesFilter"
            [matDatepicker]="picker" placeholder="{{ 'lang.latestDate' | translate }} "
            (dateChange)="datePickerChange($event)">

        <mat-datepicker disabled="false" [startAt]="latestAvailableDate" #picker></mat-datepicker>
        <button class="ml-2 mb-1 btn btn-sm btn-info" (click)="picker.open()">
            <span class="d-none d-sm-inline">{{ 'lang.chooseADate' | translate}}</span>
            <span class="d-inline d-sm-none"><i class="bi bi-calendar-date"></i></span>
        </button>
        <button class="ml-2 mb-1 btn btn-warning btn-sm dropdown-toggle" type="button" data-toggle="dropdown">
            {{ 'lang.latestDaysWithRisk' | translate}}
            <span class="caret"></span>
        </button>
        <div class="dropdown-menu riskMenu">
            <span class="dropdown-item danger clickable" href="" *ngFor="let riskyDate of riskyDates"
                (click)="goToDate(riskyDate['date'])">
                {{ dateStringToDateLabel(riskyDate["date"]) }}
                <!-- <span *ngIf="riskyDate['overtoppings'] < 2" class="badge bg-success">{{ 'lang.low_risk' | translate
                    }}</span> -->
                <span *ngIf="!riskyDate['has_overtoppings']" class="badge bg-warning">
                    {{ 'lang.medium_risk' | translate}}
                    <div class="spinner-border text-dark" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </span>
                <span *ngIf="riskyDate['overtoppings'] < 3" class="badge bg-warning">
                    {{ 'lang.medium_risk' | translate }}
                </span>
                <span *ngIf="riskyDate['overtoppings'] >= 3" class="badge bg-danger">
                    {{ 'lang.high_risk' | translate }}
                </span>
            </span>
        </div>
    </div>


</div>

<h1 class="mt-5 mb-3">{{ 'lang.riskLevelMap' | translate}} <small class="text-muted">{{ 'lang.predictionFor' |
        translate}} {{getCurrentDateHTMLLabel() |
        translate}}</small></h1>
<div class="row">
    <div class="col-lg-12 col-md-12">
        <p>{{ 'lang.riskLevelIs' | translate}}
            <span class="text-danger" *ngIf="riskLevel == 2"><strong>{{ 'lang.high' | translate}}</strong></span>
            <span class="text-warning" *ngIf="riskLevel == 1"><strong>{{ 'lang.medium' | translate}}</strong></span>
            <span *ngIf="riskLevel == 0"><strong>{{ 'lang.low' | translate}}</strong></span>
        </p>
        <!-- <p *ngIf="riskLevel > 0">El número máximo de rebases para este dia es de:
            <strong>{{maxOvertoppings}}</strong>
        </p> -->
    </div>
    <div class="col-lg-10 col-md-10">
        <div id="map" class="map"></div>
    </div>
    <div class="col-lg-2 col-md-2">
        <table class="riskTable table table-hover">
            <thead>
                <tr>
                    <th>{{ 'lang.hour' | translate}}</th>
                    <th class="w-25">{{ 'lang.risk' | translate}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of riskPerHour | keyvalue">
                    <td><b>{{item.key}}</b></td>
                    <td *ngIf="item.value == 0" class="w-25 bg-success"></td>
                    <td *ngIf="item.value == 1" class="w-25 bg-warning"></td>
                    <td *ngIf="item.value == 2" class="w-25 bg-danger"></td>
                </tr>
            </tbody>
        </table>
    </div>
</div>


<div class="row" *ngIf="false && templateSimData.length > 0">
    <div class="col-lg-12 col-md-12">
        <h1 class="my-5">{{ 'lang.simulationsCreatedForDate' | translate }}: {{getCurrentDateHTMLLabel() | translate}}
        </h1>
        <table class="table table-hover">
            <thead>
                <tr>
                    <th>{{ 'lang.sim_name' | translate }}</th>
                    <th>{{ 'lang.sim_status' | translate }}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let sim of templateSimData">
                    <td>{{simulationNameToDate(sim["name"])}}</td>
                    <td>
                        <span><b>{{ 'lang.'+sim["status"] | translate }}</b></span><br>
                        <!-- <div *ngIf="sim['status'] == 'simulation_done'">
                            <span class="ml-2">{{ 'lang.details_ot_max' | translate }}<b> {{sim["max"]}}</b></span><br>
                            <span class="ml-2">{{ 'lang.details_ot_avg' | translate }}<b> {{sim["avg"]}}</b></span><br>
                            <span class="ml-2">{{ 'lang.details_ot_std_dev' | translate }}<b>
                                    {{sim["std_dev"]}}</b></span>
                        </div> -->
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<!-- <div class="row mt-4" *ngIf="templateSimData.length == 0">
    <p [innerHTML]="'lang.noSimsCreated' | translate"></p>
</div> -->
