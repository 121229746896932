import { Component, ElementRef, OnInit } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { BackendService } from '../backend.service';
import OLMap from 'ol/Map';
import View from 'ol/View';
import VectorLayer from 'ol/layer/Vector';
import Style from 'ol/style/Style';
import Icon from 'ol/style/Icon';
import OSM from 'ol/source/OSM';
import * as olProj from 'ol/proj';
import TileLayer from 'ol/layer/Tile';
import Circle from 'ol/geom/Circle';
import { Collection, Feature } from 'ol';
import VectorSource from 'ol/source/Vector';
import Fill from 'ol/style/Fill';
import { Router } from '@angular/router';
import Stroke from 'ol/style/Stroke';
import Polygon from 'ol/geom/Polygon';
import { Coordinate } from 'ol/coordinate';
import { Utils } from '../utils';
import XYZ from 'ol/source/XYZ';

enum RiskLevel {
  Low = 0,
  Medium = 1,
  High = 2
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  availableDates: Array<Date> = new Array<Date>();
  latestAvailableDate: Date = new Date();
  simulationData: any = {};
  map: any;
  mapFeatures: Array<Feature> = [];
  riskLevel: RiskLevel = RiskLevel.Low;
  templateSimData: Array<any> = [];
  maxOvertoppings: number = 0;
  riskyDates: Array<any> = [];
  riskPerHour: Map<string, RiskLevel> = new Map();

  constructor(
    private backendService: BackendService,
    private element: ElementRef
  ) { }

  public getCurrentDateString(): string {
    return this.backendService.getCurrentDateString();
  }

  async ngOnInit(): Promise<void> {
    this.reloadData();
  }

  getFilteredRiskyDates(rawRiskyDates: Array<any>) {
    let tempRiskyDates: Array<any> = [];
    rawRiskyDates.forEach(riskyDate => {
      let dateAlreadyExisted = false;
      tempRiskyDates.forEach(filteredDate => {
        if (riskyDate['date'] == filteredDate['date']) {
          if (!filteredDate['overtoppings'] || Number(riskyDate['overtoppings']) > Number(filteredDate['overtoppings'])) {
            filteredDate['overtoppings'] = Number(riskyDate['overtoppings']);
          }
          dateAlreadyExisted = true;
        }
      });
      if (!dateAlreadyExisted) {
        // riskyDate["has_overtoppings"] = Object.keys(riskyDate).includes("overtoppings");
        riskyDate["has_overtoppings"] = true;
        tempRiskyDates.push(riskyDate);
      }
    });
console.log(tempRiskyDates);
    return tempRiskyDates;
  }

  async reloadData(): Promise<void> {
    this.availableDates = await this.backendService.getAvailableDates();
    let rawRiskyDates = await this.backendService.getRiskyDates();
    this.riskyDates = this.getFilteredRiskyDates(rawRiskyDates);

    this.latestAvailableDate = this.availableDates.sort((a, b) => b.getTime() - a.getTime())[0];
    if (!this.backendService.hasSpecificDate()) {
      this.backendService.setCurrentDate(this.latestAvailableDate);
    }
    this.simulationData = await this.backendService.getSimulationData();
    this.transformSimulationData();
    this.computeRiskLevels();
    this.initializeMap();
    this.populateRiskPerHour();
  }

  private populateRiskPerHour() {
    this.riskPerHour = new Map();
    for (let hour = 0; hour < 24; hour++) {
      let formattedHour = `${hour.toString().padStart(2, "0")}:00`;
      if (Object.keys(this.simulationData).length > 0) {
        Object.keys(this.simulationData).forEach(key => {
          let paddedHour = hour.toString().padStart(2, "0").padEnd(4, "0");
          if (key.includes(`_${paddedHour}`)) {
            // There's info for this hour
            if (this.simulationData[key]["status"] == "simulation_done") {
              let overtoppings = Number(this.simulationData[key]["overtopping_mean"]);
              if (overtoppings < 3) {
                this.riskPerHour.set(formattedHour, RiskLevel.Medium);
              } else {
                this.riskPerHour.set(formattedHour, RiskLevel.High);
              }
            } else {
              this.riskPerHour.set(formattedHour, RiskLevel.Medium);
            }
          } else {
            // There's no info for this hour
            if (!this.riskPerHour.has(formattedHour)) {
              this.riskPerHour.set(formattedHour, RiskLevel.Low);
            }
          }

        });
      } else {
        this.riskPerHour.set(formattedHour, RiskLevel.Low);
      }
    }
  }

  private transformSimulationData() {
    this.templateSimData = [];
    Object.keys(this.simulationData).forEach(key => {
      if(this.simulationData[key]["status"] != 'dont_simulate'){
        this.templateSimData.push({
          name: key,
          status: this.simulationData[key]["status"],
          max: Number(this.simulationData[key]["overtopping_mean"]).toFixed(0),
          avg: Number(this.simulationData[key]["overtopping_avg"]).toFixed(2),
          std_dev: Number(this.simulationData[key]["overtopping_std_dev"]).toFixed(2)
        });
      }
    });
  }

  private computeRiskLevels() {
    // FIXME: Take into account unfinished or failed simulations
    if (Object.keys(this.simulationData).length == 0) {
      // There was no simulations prepared to run. Low risk.
      this.riskLevel = RiskLevel.Low;
      return;
    }

    this.maxOvertoppings = 0;
    Object.keys(this.simulationData).forEach(key => {
      let currentOvertoppingMean = this.simulationData[key]["overtopping_mean"];
      if (currentOvertoppingMean && currentOvertoppingMean > this.maxOvertoppings) {
        this.maxOvertoppings = Number(currentOvertoppingMean);
      }
    });

    if (this.maxOvertoppings < 3) {
      // Less than 3 overtopping situations. Low Risk.
      this.riskLevel = RiskLevel.Medium;
    } else {
      // 3 or more overtopping situations. High Risk.
      this.riskLevel = RiskLevel.High;
    }

  }

  private initializeMap() {
    let elements = this.element.nativeElement.querySelectorAll('.ol-viewport');
    elements.forEach((element: any) => {
      element.remove();
    });

    var harborFeature = new Feature({
      geometry: new Polygon([
        [
          olProj.fromLonLat([-8.53329, 43.34586]),
          olProj.fromLonLat([-8.52364, 43.35262]),
          olProj.fromLonLat([-8.51844, 43.35467]),
          olProj.fromLonLat([-8.52026, 43.35697]),
          olProj.fromLonLat([-8.52662, 43.35484]),
          olProj.fromLonLat([-8.53578, 43.34759]),
        ]
      ]),
      name: "harbor"
    });

    let fillColor = '';
    switch (this.riskLevel) {
      case RiskLevel.Low:
        fillColor = 'rgba(0,255,0,0.5)'
        break;
      case RiskLevel.Medium:
        fillColor = 'rgba(252,100,0,0.5)'
        break;
      case RiskLevel.High:
        fillColor = 'rgba(255,0,0,0.5)'
        break;
      default:
        break;
    }

    let strokeColor = '';
    switch (this.riskLevel) {
      case RiskLevel.Low:
        strokeColor = 'rgba(0,128,0,0.5)'
        break;
      case RiskLevel.Medium:
        strokeColor = 'rgba(120,50,0,0.5)'
        break;
      case RiskLevel.High:
        strokeColor = 'rgba(128,0,0,0.5)'
        break;
      default:
        break;
    }

    harborFeature.setStyle(new Style({
      fill: new Fill({
        color: fillColor,
      }),
      stroke: new Stroke({
        color: strokeColor,
        width: 2
      })
    }));

    this.map = new OLMap({
      target: 'map',
      interactions: new Collection(),
      controls: new Collection(),
      layers: [
        new TileLayer({
          source: new XYZ({
            attributions: ['Powered by Esri',
              'Source: Esri, DigitalGlobe, GeoEye, Earthstar Geographics, CNES/Airbus DS, USDA, USGS, AeroGRID, IGN, and the GIS User Community'],
            attributionsCollapsible: false,
            url: 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
            maxZoom: 23
          })
        }),
        new VectorLayer({
          source: new VectorSource({
            features: [harborFeature],
          }),
        })
      ],
      view: new View({
        center: olProj.fromLonLat([-8.527918, 43.3510549]),
        zoom: 14,
        minZoom: 14,
        maxZoom: 14,
        enableRotation: false
      })
    });

    // this.map.on('pointermove', (event: any) => {
    //   let selectedFeatureName: string = "";
    //   this.map.forEachFeatureAtPixel(event.pixel, (feature: any) => {
    //     if (!selectedFeatureName) selectedFeatureName = feature.getProperties().name;
    //   });

    //   if (selectedFeatureName) {
    //     this.stationInfoKeys.forEach(key => {
    //       this.stationListGroupState.set(key, false);
    //     });
    //     this.stationListGroupState.set(selectedFeatureName, true);
    //     this.isPointingAtSomething = true;
    //   } else {
    //     this.stationInfoKeys.forEach(key => {
    //       this.stationListGroupState.set(key, false);
    //     });
    //     this.isPointingAtSomething = false;
    //   }
    // });

    // this.map.on('click', (event: any) => {
    //   let selectedFeatureName: string = "";
    //   this.map.forEachFeatureAtPixel(event.pixel, (feature: any) => {
    //     if (!selectedFeatureName) selectedFeatureName = feature.getProperties().name;
    //   });

    //   if (selectedFeatureName) {
    //     this.router.navigateByUrl(`/details/${this.getCurrentBranch()}/${this.getCurrentDateString()}/${selectedFeatureName}`);
    //   }
    // });

  }

  public datePickerChange(event: MatDatepickerInputEvent<Date>) {
    let eventDate: Date = event.value as Date;
    let newDate: Date = new Date();

    newDate.setFullYear(eventDate.getUTCFullYear(), eventDate.getUTCMonth(), eventDate.getUTCDate() + 1);
    newDate.setUTCHours(0, 0, 0, 0);
    this.backendService.setCurrentDate(newDate);
    this.reloadData();
  }

  availableDatesFilter = (date: Date | null): boolean => {
    if (date == null) return false;
    for (let i = 0; i < this.availableDates.length; i++) {
      const dateToCheck = this.availableDates[i];
      if (date.getFullYear() == dateToCheck.getUTCFullYear() && date.getMonth() == dateToCheck.getUTCMonth() && date.getDate() == dateToCheck.getUTCDate()) {
        return true;
      }
    }
    return false;
  }

  public getCurrentDateHTMLLabel(): string {
    return this.backendService.getCurrentDateHTMLLabel();
  }

  public simulationNameToDate(simulationName: string) {
    let dateToRet = Utils.simulationNameToDate(simulationName);
    return `${dateToRet.getUTCDate().toString().padStart(2, "0")}/${(dateToRet.getUTCMonth() + 1).toString().padStart(2, "0")}/${dateToRet.getUTCFullYear()} ${dateToRet.getUTCHours().toString().padStart(2, "0")}:${dateToRet.getUTCMinutes().toString().padStart(2, "0")}`;
  }

  public dateStringToDateLabel(dateString: string) {
    let actualDate = new Date(dateString);
    return `${(actualDate.getUTCDate()).toString().padStart(2, "0")}/${(actualDate.getUTCMonth() + 1).toString().padStart(2, "0")}/${actualDate.getUTCFullYear()}`;
  }

  public goToDate(newDateAsString: Date) {
    let actualDate = new Date(newDateAsString);
    this.backendService.setCurrentDate(actualDate);
    this.reloadData();
  }
}
