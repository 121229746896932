<nav class="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
  <a class="navbar-brand" href="#">
    <span class="font-weight-bold">Overtopping</span>
  </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar" aria-controls="navbar"
    aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbar">
    <ul class="navbar-nav">
      <li class="nav-item dropdown">
        <a [routerLink]="['/home']" routerLinkActive="active" class="nav-link">{{'lang.home' | translate}}</a>
      </li>
      <li class="nav-item dropdown">
        <a [routerLink]="['/about']" routerLinkActive="active" class="nav-link">{{'lang.about' | translate}}</a>
      </li>
    </ul>
    <ul class="navbar-nav ml-auto">
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true"
          aria-expanded="false">{{'lang.language' | translate}} </a>
        <div class="dropdown-menu dropdown-menu-dark dropdown-menu-right">
          <button class="dropdown-item" *ngFor="let language of translate.getLangs()"
            (click)="switchLang(language)">{{language | translate}}</button>
        </div>
      </li>
    </ul>

  </div>
</nav>