export class Utils {
    public static folderNameToDate(folderName: string) {
        // Folder name must be YYYYMMDD_HH
        let year: number = Number.parseInt(folderName.substr(0, 4));
        let month: number = Number.parseInt(folderName.substr(4, 2));
        let day: number = Number.parseInt(folderName.substr(6, 2));

        let dateToReturn: Date = new Date();
        dateToReturn.setFullYear(year, month - 1, day);
        dateToReturn.setUTCHours(0, 0, 0, 0);

        return dateToReturn;
    }

    public static simulationNameToDate(simulationName: string) {
        // Simulation name must be simulation_DDMMYYYY_HHMM
        let dateString = simulationName.split("simulation_")[1];
        let day: number = Number.parseInt(dateString.substr(0, 2));
        let month: number = Number.parseInt(dateString.substr(2, 2));
        let year: number = Number.parseInt(dateString.substr(4, 4));

        let hours: number = Number.parseInt(dateString.substr(9, 2));
        let minutes: number = Number.parseInt(dateString.substr(11, 2));

        let dateToReturn: Date = new Date();
        dateToReturn.setFullYear(year, month - 1, day);
        dateToReturn.setUTCHours(hours, minutes, 0, 0);

        return dateToReturn;
    }

    public static dateToFolderName(date: Date) {
        let folderName: string = `${date.getFullYear()}${(date.getMonth() + 1).toString().padStart(2, "0")}${date.getDate().toString().padStart(2, "0")}`;
        return folderName;
    }
}
